import { bool, func, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { ArrowRightIcon } from 'components/Icons'

import { Button } from './styles'

const propTypes = {
  closeCardLabel: string,
  'data-testid': string,
  isOpened: bool,
  openCardLabel: string,
  setIsOpened: func,
  show: bool,
}

const defaultProps = {
  closeCardLabel: 'Veja menos dúvidas',
  'data-testid': 'qa-card',
  openCardLabel: 'Veja mais dúvidas',
}

const OpenCta = ({
  closeCardLabel,
  'data-testid': dataTestid,
  isOpened,
  openCardLabel,
  setIsOpened,
  show,
}) => {
  const { triggerEvent } = useTriggerEvents()

  if (!show) return null
  const label = isOpened ? closeCardLabel : openCardLabel

  const handleClick = () => {
    triggerEvent({ label, pageInteraction: true })
    setIsOpened(!isOpened)
  }

  return (
    <Button
      as="a"
      variant="actionLink"
      onClick={handleClick}
      icon={ArrowRightIcon}
      isOpened={isOpened}
      label={label}
      actionType="customAction"
      data-testid={`${dataTestid}-button`}
    />
  )
}

OpenCta.propTypes = propTypes
OpenCta.defaultProps = defaultProps

export default OpenCta
