import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Grid as CreditasGrid } from 'components/Grid'

const Container = styled.div`
  position: relative;

  ${media.up('5xl')} {
    position: absolute;
    padding: 0;
    z-index: 2;
  }
`

const Grid = styled(CreditasGrid)`
  background: ${selectTheme('colors.neutral.0')};
  padding: 28px;
  box-shadow: 0 0 1px rgba(165, 174, 167, 0.9),
    0 2px 5px rgba(199, 206, 201, 0.16);
  border-radius: 20px;
  transition: all 2s ease;

  grid-column-gap: 0;
  grid-template-areas:
    '${'header '.repeat(4)}'
    '${'body '.repeat(4)}'
    '${'footer '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'header '.repeat(8)}'
      '${'body '.repeat(8)}'
      '${'footer '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '${'header '.repeat(12)}'
      '${'body '.repeat(12)}'
      '${'footer '.repeat(12)}';
    padding: 36px;
  }
`

export { Grid, Container }
