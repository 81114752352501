import { node } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: node,
}

const Description = ({ children }) => {
  if (!children) return null
  return (
    <Typography
      color="neutral.80"
      variant={{ _: 'bodyMdMedium', '5xl': 'bodyLgMedium' }}
    >
      {children}
    </Typography>
  )
}

Description.propTypes = propTypes

export default Description
