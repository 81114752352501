import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const Container = styled.div`
  grid-area: qa;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-top: 48px;

  ${media.up('5xl')} {
    padding-top: 0;
    min-height: 496px;
  }
`

export { Container }
