import { bool, string } from 'prop-types'

import Answer from './components/Answer'
import Question from './components/Question'

import { Container } from './styles'

const propTypes = {
  answer: string,
  isShown: bool,
  question: string,
}

const QuestionBox = ({ answer, isShown, question, ...others }) => {
  return (
    <Container isShown={isShown} {...others}>
      <Question>{question}</Question>
      <Answer>{answer}</Answer>
    </Container>
  )
}

QuestionBox.propTypes = propTypes
QuestionBox.typeName = 'ContentfulComponentQuestionBox'

export default QuestionBox
