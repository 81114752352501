import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

const Container = styled.div`
  border-bottom: 1px solid ${selectTheme('colors.neutral.15')};
  padding: 24px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border: 0;
    padding-bottom: 0;
  }
`

export { Container }
