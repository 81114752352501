import { node } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: node,
}

const Title = ({ children, ...others }) => {
  if (!children) return null
  return (
    <Typography
      color="neutral.90"
      variant={{
        _: 'headingMdLight',
        '5xl': 'heading2xlLight',
      }}
      dangerouslySetInnerHTML={{ __html: children }}
      {...others}
    />
  )
}

Title.propTypes = propTypes

export default Title
