import { node } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: node,
}

const Answer = ({ children }) => {
  if (!children) return null
  return (
    <Typography
      color="neutral.80"
      variant={{ _: 'bodySmRegular', '5xl': 'bodyMdRegular' }}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  )
}

Answer.propTypes = propTypes

export default Answer
