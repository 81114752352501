import { array, bool, number, string } from 'prop-types'

import QuestionBox from '../QuestionBox'

import { Body } from './styles'

const propTypes = {
  cardsToShow: number,
  'data-testid': string,
  isOpened: bool,
  qaItems: array,
}

const defaultProps = {
  'data-testid': 'qa-card',
}

const QuestionBoxList = props => {
  const { cardsToShow, 'data-testid': dataTestid, isOpened, qaItems } = props

  return (
    <Body isOpened={isOpened}>
      {qaItems.map(({ answer, question }, key) => {
        if (!isOpened && key + 1 > cardsToShow) return null

        return (
          <QuestionBox
            key={key}
            data-testid={`${dataTestid}-box`}
            question={question}
            answer={answer}
          />
        )
      })}
    </Body>
  )
}

QuestionBoxList.propTypes = propTypes
QuestionBoxList.defaultProps = defaultProps

export default QuestionBoxList
