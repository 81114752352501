import { node } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  children: node,
}

const Wrapper = ({ children, ...others }) => {
  return (
    <Section {...others}>
      <Container>
        <Grid>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes

export default Wrapper
