import { array, object, oneOf, shape, string } from 'prop-types'

import { Body, CtaWrapper, Header } from './base.styles'
import Button from './components/Button'
import CardList from './components/CardList'
import Description from './components/Description'
import Title from './components/Title'
import Wrapper from './components/Wrapper'

const propTypes = {
  buttonCta: object,
  'data-testid': string,
  description: string,
  headingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  qaCard: shape({
    closeCardLabel: string,
    openCardLabel: string,
    qaItems: array,
    title: string,
  }),
  title: string,
}

const defaultProps = {
  'data-testid': 'section-qa',
  headingTag: 'h2',
}

const Qa = ({
  buttonCta,
  'data-testid': dataTestid,
  description,
  headingTag,
  qaCard,
  title,
  ...others
}) => {
  const currentHeadingTag = headingTag ?? defaultProps.headingTag

  return (
    <Wrapper data-testid={dataTestid} {...others}>
      <Header>
        <Title as={currentHeadingTag}>{title}</Title>
      </Header>
      <Body>
        <Description>{description}</Description>
      </Body>
      <CtaWrapper>
        <Button buttonCta={buttonCta} data-testid={dataTestid} />
      </CtaWrapper>

      <CardList data-testid={dataTestid} qaCard={qaCard} />
    </Wrapper>
  )
}

Qa.propTypes = propTypes
Qa.defaultProps = defaultProps

export default Qa
