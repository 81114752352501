import { useEffect, useState } from 'react'
import { array, string } from 'prop-types'

import { setResizeHandle } from 'ui/utils/setResizeHandle'

import { QuestionCircleIcon } from 'components/Icons'

import { Footer, Header } from './base.styles'
import OpenCta from './components/OpenCta'
import QuestionBoxList from './components/QuestionBoxList'
import Title from './components/Title'
import Wrapper from './components/Wrapper'

const propTypes = {
  closeCardLabel: string,
  'data-testid': string,
  openCardLabel: string,
  qaItems: array,
  title: string,
}

const defaultProps = {
  closeCardLabel: 'Veja menos dúvidas',
  'data-testid': 'qa-card',
  openCardLabel: 'Veja mais dúvidas',
  title: 'Dúvidas frequentes',
}

const QaCard = ({
  closeCardLabel,
  'data-testid': dataTestid,
  openCardLabel,
  qaItems,
  title,
  ...others
}) => {
  const [isMobile, setIsMobile] = useState(false)
  const [isOpened, setIsOpened] = useState(false)
  const cardsToShow = isMobile ? 1 : 2

  const handleResize = ({ innerWidth }) => {
    if (innerWidth < 1024) {
      setIsMobile(true)
    }
  }

  useEffect(() => {
    setResizeHandle(handleResize)
  }, [])

  return (
    <Wrapper data-testid={dataTestid} {...others}>
      <Header>
        <QuestionCircleIcon />
        <Title>{title}</Title>
      </Header>
      <QuestionBoxList
        qaItems={qaItems}
        cardsToShow={cardsToShow}
        isOpened={isOpened}
      />
      <Footer>
        <OpenCta
          show={qaItems.length > cardsToShow}
          closeCardLabel={closeCardLabel}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          openCardLabel={openCardLabel}
          data-testid={dataTestid}
        />
      </Footer>
    </Wrapper>
  )
}

QaCard.propTypes = propTypes
QaCard.defaultProps = defaultProps
QaCard.typeName = 'ContentfulComponentQaCard'

export default QaCard
