import { css, styled } from '@creditas-ui/system'
import { getTypography, selectTheme } from '@creditas-ui/utilities'

import { ButtonCta } from 'components/ButtonCta'

const getSvgRotation = opened => {
  if (opened) return `transform: rotate(-90deg);`

  return `transform: rotate(90deg);`
}

const Button = styled(ButtonCta)(
  ({ isOpened, theme }) => css`
    margin-top: auto;
    border: 1px solid transparent;
    text-decoration: none;
    color: ${selectTheme('colors.primary.60')({ theme })};
    ${getTypography('bodySmRegular')({ theme })}

    &:hover {
      color: ${selectTheme('colors.primary.60')({ theme })};
      text-decoration: underline;
    }

    &:after {
      content: '';
      z-index: 1;
    }

    svg {
      width: 24px;
      height: 24px;
      color: ${selectTheme('colors.primary.60')({ theme })};
      display: block;
      margin-left: 16px;
      ${getSvgRotation(isOpened)}
    }
  `,
)

export { Button }
