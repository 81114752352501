import { node, string } from 'prop-types'

import { Container, Grid } from './styles'

const propTypes = {
  children: node,
  'data-testid': string,
}

const defaultProps = {
  'data-testid': 'qa-card',
}

const Wrapper = ({ children, 'data-testid': dataTestid, ...others }) => {
  return (
    <Container data-testid={dataTestid} {...others}>
      <Grid>{children}</Grid>
    </Container>
  )
}

Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default Wrapper
