import { styled } from '@creditas-ui/system'
import { getTypography } from '@creditas-ui/utilities'

import { ButtonCta } from 'components/ButtonCta'

const Button = styled(ButtonCta)`
  max-width: 148px;
  text-decoration: none;
  ${getTypography('bodySmMedium')}
`

export { Button }
