import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'
import { Grid as CreditasGrid } from 'components/Grid'

const Section = styled.section`
  background: ${selectTheme('colors.neutral.10')};
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: visible;
  color: ${selectTheme('colors.neutral.80')};
`

const Container = styled(CreditasContainer)`
  padding-top: 64px;
  padding-bottom: 64px;

  ${media.up('5xl')} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

const Grid = styled(CreditasGrid)`
  grid-template-rows: max-content;
  grid-template-areas:
    '${'header '.repeat(4)}'
    '${'body '.repeat(4)}'
    '${'cta '.repeat(4)}'
    '${'qa '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'header '.repeat(8)}'
      '${'body '.repeat(8)}'
      '${'cta '.repeat(8)}'
      '${'qa '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '${'header '.repeat(6)} . ${'qa '.repeat(5)}'
      '${'body '.repeat(5)} . . ${'qa '.repeat(5)}'
      '${'cta '.repeat(6)} . ${'qa '.repeat(5)}';
  }
`

export { Container, Grid, Section }
