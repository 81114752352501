import { styled } from '@creditas-ui/system'

const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
`

const Footer = styled.div`
  grid-area: footer;
`

export { Footer, Header }
