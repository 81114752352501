import { css, styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const OPENED_BODY_STYLE = css`
  min-height: 120px;
`

const Body = styled.div(
  ({ isOpened }) => css`
    grid-area: body;
    padding: 24px 0;

    ${media.up('5xl')} {
      padding: 32px 0;
    }

    ${isOpened && OPENED_BODY_STYLE}
  `,
)

export { Body }
