import { array, shape, string } from 'prop-types'

import Card from '../QaCard'

import { Container } from './styles'

const propTypes = {
  'data-testid': string,
  qaCard: shape({
    closeCardLabel: string,
    openCardLabel: string,
    qaItems: array,
    wrapperTitle: string,
  }),
}

const defaultProps = {
  'data-testid': 'section-qa',
}

const CardList = ({ 'data-testid': dataTestid, qaCard }) => {
  if (!qaCard?.qaItems?.length || qaCard?.qaItems?.length > 4) return null
  const { closeCardLabel, openCardLabel, qaItems, wrapperTitle } = qaCard

  return (
    <Container>
      <Card
        data-testid={`${dataTestid}-card`}
        title={wrapperTitle}
        openWrapperLabel={openCardLabel}
        closeWrapperLabel={closeCardLabel}
        qaItems={qaItems}
      />
    </Container>
  )
}

CardList.propTypes = propTypes
CardList.defaultProps = defaultProps

export default CardList
