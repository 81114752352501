import { object, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { ArrowRightIcon } from 'components/Icons'

import { Button as CreditasButton } from './styles'

const propTypes = {
  buttonCta: object,
  'data-testid': string,
}

const defaultProps = {
  'data-testid': 'section-qa',
}

const Button = ({ buttonCta, 'data-testid': dataTestid }) => {
  const { triggerEvent } = useTriggerEvents()

  if (!buttonCta?.label || !buttonCta?.actionHref) return null

  const { actionType, label } = buttonCta

  const dispatchEvent = () => {
    const isPageInteraction = actionType !== 'link'
    triggerEvent({
      label,
      pageInteraction: isPageInteraction,
    })
  }

  const buttonCtaProps = {
    ...buttonCta,
    label,
    actionType,
    variant: 'featuredButton',
    size: 'small',
    analyticEvent: false,
    onClick: dispatchEvent,
  }

  return (
    <CreditasButton data-testid={`${dataTestid}-buttoncta`} {...buttonCtaProps}>
      <ArrowRightIcon />
    </CreditasButton>
  )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
