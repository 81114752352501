import { styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { media } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)`
  text-transform: uppercase;
  padding-left: 20px;

  ${media.up('5xl')} {
    padding-left: 16px;
  }
`

export { Typography }
