import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const Header = styled.div`
  grid-area: header;
  margin: 0;
  padding: 0;

  ${media.up('5xl')} {
    padding-top: 48px;
  }
`

const Body = styled.div`
  grid-area: body;
  margin: 0;
  padding-top: 16px;

  ${media.up('5xl')} {
    padding-top: 24px;
  }
`

const CtaWrapper = styled.div`
  grid-area: cta;
  margin: 0;
  padding-top: 32px;

  ${media.up('5xl')} {
    padding: 0;
  }
`

export { Body, CtaWrapper, Header }
